:global {
    :local(.alarm_modal_wrapper) {
        .header {
            display: flex;
            align-items: center;

            .time_link {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.65);
                line-height: 22px;
                margin: 0 4px;
            }

            >.btn {
                margin-left: 24px;
                width: 60px;
                height: 32px;
                background: #357CF7;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                user-select: none;

                &:hover {
                    // add by ql
                    opacity: 0.8;
                }

                >.text {
                    height: 14px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular;
                    line-height: 14px;
                    color: #FFFFFF;
                }
            }
        }
    }
}