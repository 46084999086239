:global {
  :local(.wrapper) {
    background-color: #FFF;

    .breadcrumb {

      background-color: #FFF;
    }

    .layout-first {
      padding: 12px 12px;
      min-width: 800px;

      .layout-second {
        padding: 30px 32px;
        background-color: #FFF;

        .roleBasicInfo {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .itemTitle {
            width: 104px;
          }

          .itemValue {
            width: 300px;
          }
        }

        .fileLine {
          // height: 36px;
          width: 600px;
          display: flex;
          flex-direction: row;
          margin-top: 22px;

          // margin-bottom: 20px;
          .itemTitle {
            width: 104px;
          }

          .statusInfo {
            line-height: 30px;
            margin-left: 8px;
            color: #888;
            font-size: 13px;
          }
        }
      }
    }
  }
}