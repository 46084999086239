:global {
    :local(.wrapper) {
        height: 64px;
        display: flex;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background-color: rgba(0, 21, 41, 0.1);
        }

        >.left {
            display: flex;
            align-items: center;
            padding-left: 24px;

            >.text {
                font-size: 24px;
                font-family: PingFang SC;
                line-height: 32px;
                font-weight: 400;
                margin-top: 7px;
            }
        }

        >.right {
            width: 0;
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 24px;

            .avatar {
                margin-left: 24px;
                font-size: 18px;
                color: #0066FF;
                margin-bottom: 2px;
            }

            .user_name {
                margin-left: 8px;
                height: 14px;
                font-size: 14px;
                line-height: 14px;
                color: #0066FF;
            }

            .ant-dropdown-trigger {
                display: flex;
                align-items: center;
                cursor: pointer;

                .anticon-down {
                    color: #0066FF;
                    margin-left: 5px;
                }

            }
        }

    }

    :local(.headerDorpDown) {
        z-index: 10;

        &.ant-dropdown {

            .ant-dropdown-menu-item {
                padding-left: 24px;
                padding-right: 24px;
                line-height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #666;
                user-select: none;

                .icon {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                    font-style: normal;

                    &.logout-icon {
                        background: url('./res/logout.svg') no-repeat;
                    }

                    &.personal-icon {
                        background: url('./res/personal.svg') no-repeat;

                        &.active {
                            background: url('./res/personal_active.svg') no-repeat;
                        }
                    }
                }

                &:hover,
                &.active {
                    color: #357CF7;

                    .personal-icon {
                        background: url('./res/personal_active.svg') no-repeat !important;
                    }

                    .logout-icon {
                        background: url('./res/logout_active.svg') no-repeat !important;
                    }
                }
            }
        }

    }

}