:global {
    :local(.wrapper) {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;

        >.top {
            display: flex;
            flex-wrap: wrap;
            padding: 12px 12px 18px 12px;

            >.item {
                display: flex;
                align-items: center;
                margin: 12px 12px 0 12px;

                >.title {
                    color: #031B46;
                    font-size: 14px;
                    line-height: 20px;
                    margin-right: 12px;
                }
            }

            >.btns {
                display: flex;
                align-items: center;
                margin: 12px 12px 0 auto;
            }
        }

        >.mid {
            display: flex;
            align-items: center;
            padding: 0 8px 0 24px;

            >.title,
            >.unit {
                font-size: 14px;
                line-height: 20px;
                color: #687DA3;
            }

            >.value {
                margin-left: 8px;
                color: #0566FF;
                font-size: 14px;
                line-height: 20px;
            }
        }

        >.bottom {
            margin-top: 10px;
            height: 0;
            flex-grow: 1;
            padding: 0 24px;
            position: relative;

            >.resize_wrapper {
                position: absolute;
                top: 0;
                right: 24px;
                bottom: 0;
                left: 24px;
            }

            >.table_wrapper {
                height: 100%;
                position: relative;

                .ant-table-body {

                    //滚动条背景
                    &::-webkit-scrollbar {
                        height: 8px;
                        width: 8px;
                    }

                    // //滚动条前景
                    &::-webkit-scrollbar-thumb {
                        background-color: rgba(225, 230, 240, 1);
                        border-radius: 4px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: transparent;
                    }
                }

                th {
                    padding: 14px 0;
                    line-height: 20px;
                    background-color: #E3EDFE;


                    &::before {
                        display: none;
                    }

                    &:first-child {
                        border-radius: 4px 0 0 4px;
                    }

                    &:last-child {
                        border-radius: 0 4px 4px 0;
                    }
                }

                td {
                    padding: 18px 3px;
                    line-height: 20px;
                }
            }
        }
    }

    :local(.process_cell) {
        cursor: pointer;

        &:hover {
            background-color: rgba(231, 238, 251, 1) !important;
        }
    }
}