:global {
    :local(.alarm_page_content_wrapper) {
        height: 100%;
        position: relative;

        >.resize {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .content_wrapper {
            position: relative;
            height: 100%;
            background: rgba(255, 255, 255, 1);
            border-radius: 2px;
            box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.05);
            padding: 24px;

            .header {
                display: flex;
                align-items: center;
                padding-bottom: 24px;

                >.btn {
                    margin-left: 24px;
                    width: 60px;
                    height: 32px;
                    background: #357CF7;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    user-select: none;

                    &:hover {
                        // add by ql
                        opacity: 0.8;
                    }

                    >.text {
                        height: 14px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular;
                        line-height: 14px;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .not_end_title {
            display: flex;
            align-items: center;

            &::before {
                content: '进行中';
                display: block;
                width: 50px;
                height: 22px;
                background: linear-gradient(270deg, rgba(235, 47, 150, 1) 0%, rgba(246, 38, 76, 1) 100%);
                border-radius: 4px;
                font-size: 12px;
                color: rgba(255, 255, 255, 1);
                line-height: 22px;
                text-align: center;
                margin-right: 7px;
            }
        }

        .not_end_alarm {
            background: rgba(255, 241, 240, 1);

            // &:hover {
            //     background: rgba(255, 241, 240, 1);
            // }
            >td {
                border-width: 1px 0;
                border-color: rgba(255, 163, 158, 1);
                border-style: solid;

                &:first-child {
                    border-left-width: 1px;
                }

                &:last-child {
                    border-right-width: 1px;
                }
            }
        }
    }
}