:global{
    :local(.topBreadcrumb){
        height: 44px;
        line-height: 44px;
        display: inline-block; 
        font-size: 14px;
        &> span:last-child {
            color: #2B3249;
        }
    }
}