:global {
    :local(.wrapper) {
        padding-bottom: 24px;

        .row {
            display: flex;
            align-items: center;
            color: #3A3A3A;

            >.title {
                width: 100px;
                display: flex;

                >.required {
                    color: red;
                }

                // >.text {
                //     color: #3A3A3A;
                // }
            }

            >.content {
                width: 0;
                flex-grow: 1;
                position: relative;

                >.hint_wrapper {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transform: translateY(100%);

                    >.text {
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}