:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.btns {
            align-self: center;
        }

        >.title {
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
            margin-top: 12px;
        }

        >.range {
            text-align: center;
            font-size: 12px;
            line-height: 12px;
            margin-top: 8px;
        }

        >.bottom {
            height: 0;
            flex-grow: 1;
            position: relative;

            >.resize {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            >.chart {
                position: relative;
                overflow: hidden;
                height: 100%;
            }
        }
    }
}