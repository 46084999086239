:global {
    :local(.wrapper) {
        // min-height: 90%;

        >.display_block {
            height: 300px;
            border: 1px dashed pink;
        }

        .edit_block {
            margin-top: 14px;
        }

        .leftMetaList {
            width: 400px;
            height: 300px;
            border: 1px solid #dadada;
            background-color: #FFF;

            .meta-options-line {
                margin-left: 10px;
                margin-top: 10px;
                display: flex;
                flex-direction: row;

            }
        }

        .rightConf {
            width: 500px;
            height: 300px;
            border: 1px solid #dadada;
            padding: 12px;
            background-color: #FFFFFF;
        }
    }
}