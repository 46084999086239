:global {
    :local(.wrapper) {
        display: flex;
        align-items: center;

        >.left {
            font-size: 14px;
            line-height: 20px;
            color: rgba(51, 51, 51, 1);
            margin-right: 12px;
        }
    }
}