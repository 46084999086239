:global {
    :local(.wrapper) {
        height: 100%;
        border-radius: 4px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 1);
        position: relative;

        >.header {
            height: 56px;
            display: flex;
            align-items: center;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                bottom: 0;
                left: 0;
                right: 0;
                height: 1px;
                background-color: rgba(225, 230, 240, 1);
            }

            >.colored_bar {
                flex-shrink: 0;
                width: 4px;
                height: 32px;
                background-color: rgba(0, 102, 255, 1);
                border-radius: 0px 100px 100px 0px;
            }

            >.title {
                flex-shrink: 0;
                margin-left: 32px;
                font-size: 16px;
                line-height: 16px;
                color: rgba(0, 102, 255, 1);
                white-space: nowrap;
                margin-right: 12px;
            }

            >.right {
                margin-left: auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;

                >.item {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &.hide {
                        background-color: rgba(0, 0, 0, 0.05);
                    }

                    &:hover {
                        opacity: 0.8;
                    }

                    >.colored_line {
                        width: 18px;
                        height: 6px;
                        border-radius: 3px;
                        background-color: rgba(251, 62, 104, 1);
                        margin-right: 6px;
                    }

                    >.item_title {
                        color: rgba(104, 125, 163, 1);
                        margin-right: 12px;
                        font-size: 12px;
                        line-height: 17px;
                        white-space: nowrap;
                    }
                }
            }
        }

        >.content {
            height: 0;
            flex-grow: 1;
            position: relative;

            >.resize_wrapper {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }
    }
}