:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        >.resize_wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        >.content {
            position: relative;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}