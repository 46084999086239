:global {
    :local(.alarm_page_wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 24px;
        position: relative;

        >.tabs_wrapper {
            display: flex;
            align-items: center;
            .ant-breadcrumb {
                margin-right: 47px;
            }
        }

        >.content_wrapper {
            height: 0;
            flex-grow: 1;
        }
    }
}