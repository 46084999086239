.AlarmPushRuleDetails{
    background-color: #FFF;
    height: 100%;
    overflow-y: auto;

    .layout-second{
        padding-left: 40px;
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 40px;
        background-color: #FFF;
        min-width: 1000px;
        .ruleBasicInfo{
            margin-top: 40px;
        }
        .ruleNameInfo{
            width: 250px;
            margin-left: 5px;
        }
    }
    .ruleDetailInfo{
        margin-top: 20px;
    }

    .alertCard{
        .ant-card-body{
            padding-left: 20px;
            padding-top: 0px;
            padding-bottom: 0px;
            padding-right: 0px;
            display: block;
            height: 100%;
        }
        .inBlockLine{
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding-right: 22px;
            border-bottom: 1px solid #eee;
            line-height: 36px;
            height: 36px;
            .delBtn{
                color: #1890ff;
                cursor: pointer;
            }
        }
    }

    .ruleDetailOpt{
        margin-top: 26px;
    }
}
