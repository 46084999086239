:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 1);
        position: relative;

        >.content {
            height: 0;
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            >.top {
                height: 56px;
                display: flex;
                align-items: center;
                background-color: rgba(255, 255, 255, 1);
                padding: 0 24px;
                box-shadow: 0px 4px 16px rgba(230, 234, 242, 0.6);

                >.title {
                    margin-right: 64px;
                    font-size: 14px;
                    line-height: 20px;
                    color: rgba(104, 125, 163, 1);
                }

                >.tab-wrapper {
                    align-self: flex-end;
                }

                >.right {
                    margin-left: auto;
                    display: flex;
                    align-items: center;

                    >.sub-tab-wrapper {
                        display: flex;
                        align-items: center;

                        >.title {
                            margin-right: 8px;
                            color: rgba(51, 51, 51, 1);
                        }
                    }

                    >.title {
                        margin-left: 32px;
                        font-size: 14px;
                        line-height: 20px;
                        color: rgba(3, 27, 70, 1);
                    }
                }
            }

            >.bottom {
                height: 0;
                flex-grow: 1;
                // background-color: rgba(255, 255, 255, 1);
                // padding: 16px 24px;
                // margin-top: 4px;
                // margin-right: 12px;
                // margin-left: 12px;
            }
        }
    }
}