:global {
    :local(.wrapper) {
        height: 100%;
        border-radius: 4px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 1);
        position: relative;

        >.header {
            height: 56px;
            display: flex;
            align-items: center;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                bottom: 0;
                left: 0;
                right: 0;
                height: 1px;
                background-color: rgba(225, 230, 240, 1);
            }

            >.colored_bar {
                width: 4px;
                height: 32px;
                background-color: rgba(0, 102, 255, 1);
                border-radius: 0px 100px 100px 0px;
            }

            >.title {
                margin-left: 32px;
                font-size: 16px;
                line-height: 16px;
                color: rgba(0, 102, 255, 1);
                white-space: nowrap;
                margin-right: 12px;
            }

        }

        >.content {
            height: 0;
            flex-grow: 1;
            position: relative;

            >.resize_wrapper {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            >.table_wrapper {
                position: relative;
                height: 100%;
                padding: 15px 16px 0 16px;

                th {
                    background-color: rgba(227, 237, 254, 1) !important;
                }

                tbody {
                    >tr {
                        &:nth-child(odd) {
                            background-color: rgba(249, 250, 252, 1);
                        }

                        >td {
                            &:nth-child(n+2) {
                                color: rgba(53, 91, 153, 1);
                            }
                        }
                    }
                }
            }
        }
    }
}