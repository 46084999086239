:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        >.grid_wrapper {
            height: 0;
            flex-grow: 1;
            // border: 1px solid rgba(0, 0, 0, 0.5);
            overflow-y: auto;
        }
    }
}