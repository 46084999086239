:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        // padding: 0 16px;

        >.bottom {
            height: 0;
            flex-grow: 1;
        }
    }
}