:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: rgba(245, 245, 245, 1);
        position: relative;

        >.content {
            height: 0;
            flex-grow: 1;
            background-color: rgba(255, 255, 255, 1);
            padding: 16px 24px;

            >.content {
                height: 100%;
                display: flex;
                flex-direction: column;
                box-shadow: 0px 0px 16px rgba(172, 185, 207, 0.15);
                border-radius: 8px;
                background-color: rgba(255, 255, 255, 1);

                >.top {
                    height: 54px;
                    display: flex;
                    align-items: center;
                    padding: 0 24px;

                    >.title {
                        font-size: 14px;
                        line-height: 20px;
                        color: rgba(104, 125, 163, 1);
                    }
                }

                >.bottom {
                    border-top: 1px solid rgba(0, 21, 41, 0.1);
                    height: 0;
                    flex-grow: 1;
                }
            }
        }
    }
}