:global {
    :local(.out_wrapper) {
        height: 100%;
        background-color: rgba(255, 255, 255, 1);
        position: relative;
    }

    :local(.wrapper) {
        height: 100%;
        padding: 23px 24px 25px 24px;
        position: relative;

        >.content {
            height: 100%;
            border-radius: 8px;
            box-shadow: 0px 0px 16px rgba(172, 185, 207, 0.15);
            display: flex;
            flex-direction: column;
            padding-bottom: 12px;

            >.top {
                height: 56px;
                padding-left: 26px;
                display: flex;
                align-items: center;
                box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
                font-size: 14px;
                line-height: 20px;
                color: rgba(51, 51, 51, 1);
            }

            >.mid_1 {
                height: 56px;
                box-shadow: 0px 4px 16px rgba(230, 234, 242, 0.6);
                display: flex;
                padding: 0 24px;

                >.left {
                    align-self: flex-end;
                    width: 0;
                    flex-grow: 1;
                    overflow: hidden;
                }

                >.right {
                    align-self: center;
                }
            }

            >.mid_2 {
                display: flex;
                justify-content: flex-end;
                padding: 24px 24px 16px 0;
            }

            >.bottom {
                height: 0;
                flex-grow: 1;
                padding: 0 0 12px 12px;
                overflow-y: auto;
                overflow-x: hidden;

                >.content {
                    width: 0;
                    display: grid;
                    gap: 12px;

                    >.item {
                        width: 100%;
                        height: 128px;
                        border: 1px solid rgba(201, 201, 201, 0.65);
                        background-color: rgba(249, 250, 252, 0.65);
                        border-radius: 4px;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;

                        >.top {
                            height: 40px;
                            background-color: rgba(94, 123, 196, 1);
                            padding: 4px 16px 0 16px;
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            line-height: 20px;
                            color: rgba(255, 255, 255, 1);

                            >.left {
                                margin-right: auto;
                            }

                            >.icon {
                                width: 16px;
                                height: 16px;
                                cursor: pointer;
                            }
                        }

                        >.bottom {
                            height: 0;
                            flex-grow: 1;
                            display: flex;
                            padding: 0 16px;

                            >.left {
                                align-self: center;
                                margin-top: 2px;
                                margin-right: 16px;
                                font-size: 36px;
                                line-height: 50px;
                                font-weight: bold;
                                color: rgba(53, 91, 153, 1);
                                text-decoration: underline;
                                cursor: pointer;
                            }

                            >.right {
                                align-self: flex-end;
                                padding-bottom: 18px;
                                font-size: 12px;
                                line-height: 17px;
                                color: rgba(102, 102, 102, 1);
                            }
                        }
                    }
                }
            }
        }
    }

    :local(.tab_close) {
        margin-left: 4px;
        width: 16px;
        height: 16px;
        position: relative;
        bottom: 1px;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
}