:global {
    :local(.wrapper) {
        height: 100%;
        padding: 24px 72px 0 72px;
        display: flex;
        flex-direction: column;
        .content-top{
            width: 100%;
            height: 56px;
            line-height: 56px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #357CF7;
            background-color: #fff;
            padding: 0 24px;
            border-radius: 4px 4px 0 0;
            border-bottom: 1px solid #C9C9C9;
        }

        .content-main{            
            height: 0;
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            .left{
                width: 256px;
                background-color: #FAFAFA;
                padding-top: 24px;
                >.content_wrapper {
                    height: 100%;
                    overflow: auto;
                    .ant-tree{
                        background-color: #FAFAFA;
                        .ant-tree-treenode-selected{
                            background-color: #F5F5F5 !important;
                            .ant-tree-node-selected{
                                background-color: transparent !important;                              
                            }                            
                        }
                        .ant-tree-node-content-wrapper:hover {
                            background-color: transparent !important; 
                        }
                    }
                }
            }
            .right{
                flex-grow: 1;
                background-color: #fff;
                padding: 24px;
                .content_wrapper{
                    width: 600px;
                    .btn_wrapper{
                        text-align: right;
                    }
                }
            }
        }

        >.left {
            width: 350px;
            background-color: #ffffff;
            border-radius: 16px;
            box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.05);
            margin-right: 24px;
            padding: 24px 12px;

            >.content_wrapper {
                height: 100%;
                overflow: auto;
            }
        }

        >.right {
            width: 0;
            flex-grow: 1;
            max-width: 1400px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;

            >.top {
                height: 56px;
                background-color: #ffffff;
                border-radius: 16px;
                box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.05);
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-right: 24px;

                >.add_btn_wrapper {
                    width: 88px;
                    height: 32px;
                    background: #357CF7;
                    border-radius: 16px;
                    padding-left: 16px;
                    display: flex;
                    align-items: center;
                    user-select: none;
                    cursor: pointer;

                    &:hover {
                        // add by ql
                        opacity: 0.8;
                    }

                    >.icon {
                        width: 16px;
                        height: 16px;
                    }

                    >.text {
                        margin-left: 8px;
                        height: 14px;
                        font-size: 14px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        line-height: 14px;
                        color: #FFFFFF;
                    }
                }
            }

            >.bottom {
                margin-top: 24px;
                height: 0;
                flex-grow: 1;
                background-color: #ffffff;
                border-radius: 16px;
                box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.05);
                padding: 24px;

                >.content_wrapper {
                    width: 500px;

                    >.btn_wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
}