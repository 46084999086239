:global {
    :local(.wrapper) {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            left: -24px;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 24px;
            border-width: 2px 0 2px 4px;
            border-style: solid;
            border-color: transparent transparent transparent rgba(53, 124, 247, 1);
        }
    }

}