:global {
    :local(.wrapper) {
        // min-height: 90%;

        >.display_block {
            height: 300px;
            border: 1px dashed pink;
        }

        >.edit_block {
            margin-top: 14px;
        }

    }
}